@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  background-color: #1f134e;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  overflow-y: none;

  overflow-x: hidden;
  min-height: 100vh;
}

main {
  width: 100%;
  min-height: 100vh;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  flex: 1 1;
  align-items: center;
  justify-content: center;
}

.accent {
  color: #00e3a3;
}

/* Statistics card (main card for graphs ect) */
.stat-card-container {
  margin-bottom: 30px;
}

.stat-card-container .card-body {
  padding: 0.5rem 10px 20px 0;
}

.stat-card-container .card-body div {
  width: 100% !important;
}

/* Small statistics card (for the small statistics cards eg 10 new configurations) */
.small-stat-card {
  margin-bottom: 30px;
}

.small-stat-card .icon-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-stat-card h3 {
  font-weight: 800;
  margin-bottom: 5px;
  /* font-size: 12px; */
}

.small-stat-card h6 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 700;
}

/* Card */
.card {
  background-color: #2e1d66;
  border-radius: 7px;
  border: 0;
  min-height: 100%;
}

.card-header {
  background-color: transparent;
  border-bottom: 0;
  font-size: 16px;
  font-weight: 800;
}

.card-body,
.card-header {
  padding: 1.25rem 1.25rem;
}

.display-graph .card {
  height: 380px;
}

/* Page header */
.page-header {
  margin-top: 20px;
  margin-bottom: 50px;
}

.page-header h1 {
  text-align: center;
  font-weight: 600;
}

.logo {
  align-self: center;
  margin: 0 auto;
  height: 5vw;
}

.logo-container {
  display: flex;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

/* Navbar */
.navbar a {
  color: #fff;
}

.navbar.navbar-brand {
  color: #fff;
}

.navbar.navbar-brand:hover {
  color: #fff;
}

/* Inline select */
.inline-select-container {
  height: 21px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  width: 130px;
}

.inline-select-container select {
  display: inline-block;
  background: rgba(0, 0, 0, 0.3);
  width: 130px;
  position: absolute;
  top: 16px;
  border: none;
  color: #fff !important;
  padding: 0 10px;
  box-shadow: none !important;
}

.inline-select-container select option {
  background-color: rgb(6, 8, 24);
  border: 0;
}

.inline-select-container select:focus {
  background: rgba(0, 0, 0, 0.3);
}

/* Loading/Other message */
h1.card-message {
  text-align: center;
  font-style: italic;
  margin-top: 20px;
  font-size: 1.5em;
}

/* Form control */
input.form-control {
  background: rgba(255, 255, 255, 0.04);
  border: 0;
  color: #fff;
}

input.form-control:focus {
  background: rgba(255, 255, 255, 0.04);
  color: #fff;
  border: 0;
  box-shadow: none !important;
}

/* Table */
.table th {
  border: 0;
}

.table thead th {
  border: 0;
  border-bottom: none !important;
  font-weight: 300;
}

.table tbody tr a {
  font-weight: 300;
}

.table td {
  border: 0;
}

/* Customers table */
.load-more-container {
  text-align: center;
}

.load-more-container button.load-more {
  border: 0;
  background: transparent;
  box-shadow: none !important;
}

/* Wider container */
.container.wide {
  max-width: 1290px;
}

